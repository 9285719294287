






























































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

import { api as appApi } from "@/store/modules/app";
import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as userGroupsApi } from "@/store/modules/userGroups/index";
import { api as userManagementApi } from "@/store/modules/userManagement";
import { api as configApi } from "@/store/modules/configuration";

import { ROUTE_NAMES } from "@/routenames";

import { validate as validDisplayName } from "@auditcloud/shared/lib/utils/sanitizer/displayName";
import { validate as validMail } from "@auditcloud/shared/lib/utils/sanitizer/email";
import { SystemRoles } from "@auditcloud/shared/lib/constants/roles";
import {
  userHasPortalAccess,
  userUpdateRemovesPortalAccess,
} from "@auditcloud/shared/lib/utils/shares/utils";
import {
  Group,
  assignableGroups,
  getActiveGroups,
} from "@auditcloud/shared/lib/utils/usermanagement/groups";
import {
  ApiError,
  ApiUser,
  UpdateUserPayload,
  UpdateUserResult,
} from "@/store/modules/userManagement/types";
import { CollectionObserverConfig } from "@/utils/firestore";
import { flatten } from "lodash";
import {
  arrayHasTheSameContent,
  copyToClipboard,
} from "@auditcloud/shared/lib/utils";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";
import { Result } from "neverthrow";

const TYPES = ["login", "ref", "sync"] as const;
type TypeChoice = typeof TYPES[number];

type ValidationRule = (v: any) => boolean | string;

@Component({})
export default class AUserDetails extends Vue {
  @Prop({ type: String })
  readonly userId!: string;

  readonly displayNameRules: ValidationRule[] = [
    v =>
      validDisplayName(v) ||
      this.$i18n.t("common.rules.name_required").toString(),
  ];
  readonly emailRules: ValidationRule[] = [
    v => !!v || this.$i18n.t("common.rules.email_required").toString(),
    v => validMail(v) || this.$i18n.t("common.rules.email_invalid").toString(),
  ];

  type: TypeChoice = "ref";
  active: boolean = false;
  displayName: string = "";
  email: string = "";
  applicationGroups: string[] = [];

  isFormValid: boolean = false;
  result: UpdateUserResult | null = null;
  sharesResult: Result<unknown, unknown> | null = null;

  @Getter(userManagementApi.getters.getUser, {
    namespace: userManagementApi.namespace,
  })
  user!: ApiUser | null;

  @Getter(userManagementApi.getters.getError, {
    namespace: userManagementApi.namespace,
  })
  error!: ApiError | null;

  @Getter(userManagementApi.getters.isLoading, {
    namespace: userManagementApi.namespace,
  })
  isLoading!: boolean;

  @Getter(userGroupsApi.getters.getGroupList, {
    namespace: userGroupsApi.namespace,
  })
  allGroups!: Group[];

  @Getter(userApi.getters.getCurrentUserRoles, { namespace: userApi.namespace })
  currentUserRoles!: UserGetters["getCurrentUserRoles"];

  @Getter(configApi.getters.customerPortalFeatureEnabled, {
    namespace: configApi.namespace,
  })
  customerPortalFeatureEnabled!: boolean;

  copy(text: string) {
    copyToClipboard(text);
  }

  get assignableTypes(): { value: string; text: string }[] {
    return TYPES.map(value => ({
      value,
      text: this.$t(`views.user-management.user-types.${value}`).toString(),
      disabled: value === "sync",
    }));
  }

  get assignableGroups(): Group[] {
    return assignableGroups(this.currentUserRoles, this.allGroups);
  }

  get activeGroups() {
    return getActiveGroups(this.allGroups).map(group => ({
      ...group,
      /** only allow system-admins to edit the system-admin-group */
      disabled:
        group.roles.includes(SystemRoles.SYSTEM_ADMINISTRATOR) &&
        !this.$user.roles().includes(SystemRoles.SYSTEM_ADMINISTRATOR),
    }));
  }

  /** The user's current roles */
  get userRoles() {
    if (this.user == null) {
      return [];
    }
    const userGroupIds = [
      ...(this.user.groups || []),
      ...(this.user.applicationGroups || []),
    ];
    return [
      ...flatten(
        userGroupIds.map(
          groupId =>
            this.allGroups.find(({ id }) => id === groupId)?.roles ?? []
        )
      ),
      ...this.user.roles,
    ];
  }

  /** The user's new roles (after saving the changes) */
  get newUserRoles() {
    if (this.user == null) {
      return [];
    }
    const userGroupIds = [
      ...(this.user.groups || []),
      ...(this.applicationGroups || []),
    ];
    return [
      ...flatten(
        userGroupIds.map(
          groupId =>
            this.allGroups.find(({ id }) => id === groupId)?.roles ?? []
        )
      ),
      ...this.user.roles,
    ];
  }

  /** true, if the current user not a system admin, but
   * the edited user is a system admin */
  get currentUserIsInferiorAdmin(): boolean {
    if (!this.$user.isAdmin()) {
      return true;
    }

    if (this.currentUserRoles.includes(SystemRoles.SYSTEM_ADMINISTRATOR)) {
      return false;
    }

    return this.userRoles.includes(SystemRoles.SYSTEM_ADMINISTRATOR);
  }

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.settings.breadcrumb"),
        to: { name: ROUTE_NAMES.SETTINGS },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.user-title"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT_USERS },
        activeclass: "",
      },
      {
        text: this.displayName,
        to: { name: ROUTE_NAMES.USER_MANAGEMENT_USER_DETAILS },
        activeclass: "",
      },
    ];
  }

  get errorMessage(): string {
    if (this.result == null || this.result.isOk()) {
      return "";
    }

    const { code = null, payload = {} } = this.result.error;
    const messageId = `views.user-management.user-update-errors.${code}`;
    if (code && this.$te(messageId)) {
      return this.$t(messageId, payload).toString();
    }
    const fallbackId = "views.user-management.user-update-errors.UNKNOWN";
    return this.$t(fallbackId, { code: code ?? "UNKNOWN" }).toString();
  }

  get sharesErrorMessage(): string {
    return this.$t(
      "views.user-management.user-shares-errors.error_disabling_shares"
    ).toString();
  }

  get isRefUser(): boolean {
    return this.user === null || this.type === "ref";
  }

  get isSyncUser(): boolean {
    return this.user === null || this.type === "sync";
  }

  get hasPortalAccess(): boolean {
    return (
      !!this.user &&
      userHasPortalAccess(
        this.user.type,
        this.user.active,
        this.user.portalAccess
      )
    );
  }

  get isDirty(): boolean {
    if (this.user === null) {
      return false;
    }

    return (
      this.type !== this.user.type ||
      this.email !== this.user.email ||
      this.displayName !== this.user.displayName ||
      this.active !== this.user.active ||
      !arrayHasTheSameContent(
        this.user.applicationGroups ?? [],
        this.applicationGroups
      )
    );
  }

  get idLabel(): string {
    return this.isRefUser
      ? this.$t("views.user.id_lb_ref").toString()
      : this.$t("views.user.id_lb").toString();
  }

  @Watch("allGroups")
  handleAllGroupsChange(newVal) {
    // when directly entering the user-detail page URL, the groups will only be
    // displayed, when allGroups are loaded
    if (newVal.length > 0) {
      this.handleCurrentUserChange();
    }
  }

  @Watch("user", { deep: true })
  handleCurrentUserChange() {
    if (this.user === null) {
      return;
    }
    const { user } = this;
    this.type = user.type;
    this.active = user.active;
    this.displayName = user.displayName;
    this.email = user.email;

    // Filter out unknown groups to avoid errors when saving:
    this.applicationGroups = (user.applicationGroups ?? []).filter(id =>
      this.activeGroups.some(group => group.id === id)
    );
    this.setBreadCrumb(this.currentBreadCrumb);
  }

  created() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();

    this.findUser(this.userId);
    this.listenForGroups({});
  }

  beforeDestroy() {
    this.unlistenGroups();
  }

  @Action(userGroupsApi.actions.listenOnCollection, {
    namespace: userGroupsApi.namespace,
  })
  listenForGroups!: (config: CollectionObserverConfig) => void;

  @Action(userGroupsApi.actions.clearDocuments, {
    namespace: userGroupsApi.namespace,
  })
  unlistenGroups!: () => void;

  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Action(userManagementApi.actions.findUser, {
    namespace: userManagementApi.namespace,
  })
  findUser!: (id: string) => Promise<void>;

  @Action(userManagementApi.actions.updateUser, {
    namespace: userManagementApi.namespace,
  })
  updateUser!: (payload: UpdateUserPayload) => Promise<UpdateUserResult>;

  @Action(userManagementApi.actions.disableSelfAssessmentShares, {
    namespace: userManagementApi.namespace,
  })
  disableSelfAssessmentShares!: (payload: {
    userId: string;
  }) => Promise<UpdateUserResult>;

  async handleSubmit() {
    if (this.user) {
      if (
        this.customerPortalFeatureEnabled &&
        userUpdateRemovesPortalAccess(
          this.user.type,
          this.type,
          this.user.active,
          this.active,
          this.user.portalAccess,
          this.user.portalAccess
        )
      ) {
        const confirmation = confirm(
          this.$t(
            "views.user-management.user-update-hints.update_disables_user_portal_access"
          ).toString()
        );
        if (!confirmation) {
          return;
        }
      }
    }

    this.result = null;
    const { userId, type, active, displayName, email, applicationGroups } =
      this;

    this.result = await this.updateUser({
      id: userId,
      type,
      active,
      displayName,
      email,
      applicationGroups,
    });
    this.findUser(this.userId);
  }

  async disableAllSelfAssessmentShares() {
    if (
      confirm(
        this.$t(
          "views.user-management.user-update-hints.disable_self_assessment_shares_confirmation"
        ).toString()
      )
    ) {
      await this.disableSelfAssessmentShares({ userId: this.userId });
    }
  }
}
